<template>
  <div class="home">
    <div class="top">
      <div class="top_left">
        <h1>{{$root.messages.t1}} <br> {{$root.messages.t2}} <br> {{$root.messages.t3}}</h1>
        <p>{{$root.messages.t4}}</p>
        <div style="margin-top: 32px;">
          <img @click="$router.push('/Pricing')" style="width: 170px;" src="../assets/buy.png" alt="">
        </div>
      </div>
      <div class="top_right">
        <img src="../assets/home_right.png" alt="">
      </div>
    </div>


    <div class="content">
      <div class="c_div">
        <img src="../assets/icon1.png" alt="">
        <h1 style="margin-top: 20px;">{{$root.messages.t5}}</h1>
        <p style="margin-top: 30px;"> {{$root.messages.t6}} </p>
        <p> {{$root.messages.t7}} </p>
        <p> {{$root.messages.t8}} </p>
      </div>
      <div class="c_div">
        <img src="../assets/icon5.png" alt="">
        <h1 style="margin-top: 20px;">{{$root.messages.t9}}</h1>
        <p style="margin-top: 30px;"> {{$root.messages.t10}} </p>
        <p> {{$root.messages.t11}} </p>
        <p> {{$root.messages.t12}} </p>
      </div>

      <div class="c_div">
        <img src="../assets/icon6.png" alt="">
        <h1 style="margin-top: 20px;">{{$root.messages.t13}}</h1>
        <p style="margin-top: 30px;"> {{$root.messages.t14}} </p>
        <p> {{$root.messages.t15}} </p>
        <p> {{$root.messages.t16}} </p>
      </div>
      <div class="c_div">
        <img src="../assets/icon7.png" alt="">
        <h1 style="margin-top: 20px;">{{$root.messages.t17}}</h1>
        <p style="margin-top: 30px;"> {{$root.messages.t18}} </p>
        <p> {{$root.messages.t19}} </p>
        <p> {{$root.messages.t20}} </p>
      </div>
      <div class="c_div">
        <img src="../assets/icon8.png" alt="">
        <h1 style="margin-top: 20px;">{{$root.messages.t21}}</h1>
        <p style="margin-top: 30px;"> {{$root.messages.t22}} </p>
        <p> {{$root.messages.t23}} </p>
        <p> {{$root.messages.t24}} </p>
      </div>
      <div class="c_div">
        <img src="../assets/icon9.png" alt="">
        <h1 style="margin-top: 20px;">{{$root.messages.t25}}</h1>
        <p style="margin-top: 30px;"> {{$root.messages.t26}} </p>
        <p> {{$root.messages.t27}} </p>
        <p> {{$root.messages.t28}} </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 768px) {
 .c_div {
  width: 100% !important;
  min-width: 260px;
 } 
}
  .content {
    width: 95%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 100px;
    margin-top: 37px;
    .c_div {
      width: 30%;
      padding: 20px 30px 25px;
      border: 2px solid #153C3C;
      text-align: center;
      border-radius: 12px;
      margin-top: 50px;
      box-sizing: border-box;
      box-shadow: 1px 2px 50px 1px rgba(21,60,60,0.1);
      img {
        width: 100px;
      }
      h1 {
        font-weight: 800;
        font-size: 40px;
        color: #172326;
        font-family: SourceHanSerifCN, SourceHanSerifCN;
        font-style: normal;
      }
      p {
        font-weight: 400;
        font-size: 30px;
        color: #172326;
        font-family: SourceHanSerifCN, SourceHanSerifCN;
        font-style: normal;
      }
    }
  }
</style>
