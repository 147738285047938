import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(Element)
import enMessages from './lang/en.json'
import inMessages from './lang/in.json'
var lang = localStorage.getItem('lang') || 'en';
const messages = lang === 'en' ? enMessages : inMessages;

var data = {
  lang: lang,
  messages: messages
};


Vue.config.productionTip = false

new Vue({
  router,
  store,
  data: data,
  methods: {
    // 切换语言方法
    qiehuan: function (e) {
      localStorage.setItem('lang', e);
      this.lang = e;
      this.messages = e === 'en' ? enMessages : inMessages;
    }
  },
  render: h => h(App)
}).$mount('#app')
