import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/MBTsoftware',
    name: 'MBTsoftware',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/Pricing',
    name: 'Pricing',
    component: () => import('../views/Pricing2.vue')
  },
  {
    path: '/faqs',
    name: 'faqs',
    component: () => import('../views/faqs.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue')
  },
  {
    path: '/term',
    name: 'term',
    component: () => import('../views/term.vue')
  },
  {
    path: '/check',
    name: 'check',
    component: () => import('../views/check.vue')
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/checkout.vue')
  }
]
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.afterEach((to, from) => {
  // 滚动到页面顶部
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // 使用平滑滚动效果
  });
});

export default router
