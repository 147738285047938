<template>
  <div id="app">
    <div class="nav_div">
      <div class="nav_left">
        <img src="./assets/logo.png" alt="">
      </div>
      <nav class="navdaohang">
        <router-link to="/">BRAND</router-link>
        <router-link to="/MBTsoftware">MBTsoftware</router-link>
        <router-link to="/Pricing">{{$root.messages.t31}}</router-link>
        <!-- <router-link to="/Service">Service</router-link> -->
        <el-dropdown trigger="click" @command="navigateToPage">
          <span class="el-dropdown-link">
            Service <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item  command="/faqs">FAQs</el-dropdown-item>
            <el-dropdown-item  command="/contact">Contact</el-dropdown-item>
            <el-dropdown-item  command="/term">Term and Condition</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </nav>
      <div class="nav_right">
        <div @click="clickEN">
          <img style="width: 32px;" src="./assets/en.png" alt="">
          <span :class="dangqian === '1'?'aaa':''">EN</span>
        </div>
        <div @click="clickIN">
          <img style="width: 32px;" src="./assets/in.png" alt="">
          <span :class="dangqian === '2'?'aaa':''">IN</span>
        </div>
      </div>
      <i @click="drawer = true" style="color: #fff;font-size: 44px;display: none;" class="icon111 el-icon-s-fold"></i>
    </div>
    <router-view style="margin-top: 110px;padding-top: 50px;" />
    <div class="app_b">
      <div>
        <span style="font-weight: bold;">Contacts</span>
      </div>
      <div class="zhongjian" style="margin: 50px 0px;">
        <span style="font-weight: bold;">Alamat</span> : JL. RAJAWALI SELATAN 1 NOMOR 1B, Desa/Kelurahan Gunung Sahari Utara, Kec. Sawah Besar,  <br>
        Kota Adm. Jakarta Pusat, Provinsi DKI Jakarta, Kode Pos: 10720:
      </div>
      <div style="display: flex;flex-wrap: wrap;">
        <div style="min-width: 200px;margin-right: 15px;">
          <span style="font-weight: bold;">No hp</span>: 0878 3832 25274
        </div>
        <div>
          <span style="font-weight: bold;">Email</span>: ptmelangkahbersamateknologi@gmail.com
        </div>
      </div>
      <div style="margin-top: 50px;font-weight: bold;">
        PT MELANGKAH BERSAMA TEKNOLOGI 
      </div>
    </div>
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      direction="rtl"
      :show-close="false"
      :with-header="false">
      <div style="padding: 30px;">
        <div style="position: relative;">
          <img src="./assets/logo.png" alt="">
          <img @click="drawer = false" style="width: 44px;position: absolute;top: 0px;right: 0px;" src="./assets/guanbi.png" alt="">
        </div>
        <div style="margin-top: 66px;">
          <div class="daohang" @click="drawer = false,$router.push('/')">BRAND</div>
          <div class="daohang" @click="drawer = false,$router.push('/MBTsoftware')">MBTsoftware</div>
          <div class="daohang" @click="drawer = false,$router.push('/Pricing')">{{$root.messages.t31}}</div>
          <div class="daohang" @click="drawer = false,$router.push('/faqs')">FAQs</div>
          <div class="daohang" @click="drawer = false,$router.push('/contact')">Contact</div>
          <div class="daohang" @click="drawer = false,$router.push('/term')">Term and Condition</div>
        </div>
        <div style="margin-top: 30px;">
          <h4>Contacts</h4>
          <div style="font-family: 微软雅黑;color: rgb(99 100 101);margin-top: 15px;">
            <div>
              <i class="el-icon-message" style="color: #6EC1E4;"></i>
              ptmelangkahbersamateknologi@gmail.com
            </div>
            <div style="margin: 15px 0px;">
              <i class="el-icon-phone" style="color: #6EC1E4;"></i>
              0878 3832 25274
            </div>
            <div>
              <i class="el-icon-location" style="color: #6EC1E4;"></i>
              JL. RAJAWALI SELATAN 1 NOMOR 1B, Desa/Kelurahan Gunung Sahari Utara, Kec. Sawah Besar,
        Kota Adm. Jakarta Pusat, Provinsi DKI Jakarta, Kode Pos: 10720:
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>
<script>
export default {
  data() {
    return {
        // messages: {}
      dangqian: '1',
      drawer: false
    };
  },
  created() {
    var aa = localStorage.getItem('lang')
    if (aa === 'in') {
      this.dangqian = '2'
    } else {
      this.dangqian = '1'
    }
  },
  methods: {
    clickEN() {
      this.$root.qiehuan('en');
      this.dangqian = '1'
    },
    clickIN() {
      this.$root.qiehuan('in');
      this.dangqian = '2'
    },
    navigateToPage(e) {
      const selectedPage = e;
      if (selectedPage) {
        this.$router.push(selectedPage);
      }
    }
  }
}
</script>

<style lang="scss">
.daohang {
  border-bottom: 1px solid rgba(2, 11, 24, 0.1);
  color: #000000;
  font-size: 18px;
  padding: 10px 0;
  font-weight: bold;
}
.el-drawer {
  width: 100% !important;
}
@media only screen and (max-width: 768px) {
  .navdaohang,.nav_right {
    display: none !important;
  }
  .icon111 {
    display: block !important;
  }
  .nav_div {
    justify-content: space-between;
  }
  .app_b {
    padding: 15px !important;
    font-size: 16px !important;
  }
  .zhongjian {
    margin: 15px 0px !important;
  }
  .top {
    width: 95% !important;
    flex-wrap: wrap;
    margin-top: 0px !important;
    .top_left {
      width: 100% !important;
      padding-top: 0px !important;
      h1 {
        font-size: 46px !important;
      }
      p {
        font-size: 24px !important;
      }
    }
    .top_right {
      width: 100% !important;
    }
  }
}
.el-dropdown {
  font-weight: 400 !important;
  color: #ffffff !important;
  display: block !important;
  padding: 5px 40px !important;
  font-size: 30px !important;
  text-decoration: none !important;
}
.el-dropdown-menu__item {
  text-align: center;
}
.el-dropdown-menu {
  border: none !important;
}
.el-dropdown-menu__item:focus, .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #153C3C !important;
  color: #ffffff !important;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

* {
  margin: 0px;
}
.nav_div {
  background: #153C3C;
  padding: 30px;
  display: flex;
  box-sizing: border-box;

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  .nav_left {
    width: 30%;
    img {
      width: 76px;
    }
  }
  nav {
    display: flex;
    width: 70%;
    // height: 50px;
    a {
      font-weight: 400;
      color: #ffffff;
      display: block;
      padding: 5px 40px;
      font-size: 30px;
      text-decoration: none;
      &.router-link-exact-active {
        color: #ffffff;
        font-weight: bold;
        border: 1px solid #ffffff;
      }
    }
  }
  .nav_right {
    width: 10%;
    padding: 10px 15px;
    background: #84A17D;
    display: flex;
    justify-content: space-between;
    div {
      display: flex;
      align-items: center;
      span {
        font-size: 24px;
        color: #FFFFFF;
      }
      .aaa {
        font-weight: bold;
      }
    }
  }
}
.app_b {
  font-family: SourceHanSansCN, SourceHanSansCN;
  background: #E2ECDF;
  padding: 83px 110px;
  font-size: 1.5vw;
}

.top {
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 90px;
    .top_left {
      width: 50%;
      padding-top: 54px;
      h1 {
        font-family: SourceHanSerifCN, SourceHanSerifCN;
        font-weight: 800;
        color: #172326;
        text-align: left;
        font-size: 80px;
        font-style: normal;
      }
      p {
        font-size: 30px;
        font-weight: 400;
        font-family: SourceHanSansCN, SourceHanSansCN;
        color: #153C3C;
        font-style: normal;
        margin-top: 30px;
        line-height: 46px;
      }
    }
    .top_right {
      width: 45%;
      img {
        width: 100%;
      }
    }
  }
</style>
